<template>
    <h2 class="mt-4">
        Что это?
    </h2>
    <p>
        Это генератор лоскутного одеяла, который позволяет создать уникальный рисунок для одеяла с квадратными лоскутами. При этом генератор постарается расположить все цвета равномерно по всему полотну одеяла.
    </p>
    <h2>Как это работает?</h2>
    <p>
        Работа с этим генератором состоит из нескольких этапов:
    </p>
    <ol>
        <li>Введите количество лоскутов в ширину и высоту. Размеры самих лоскутов не имеют значения.</li>
        <li>Укажите сколько групп лоскутов у вас есть.</li>
        <li>На следующем этапе необходимо указать сколько лоскутов есть в каждой группе.</li>
        <li>И последнее нажать на кнопку «Сгенерировать». После чего будет сгенерирована картинка показывающая расположение каждого лоскута в будущем одеяле. Так же будет сгенерирована таблица в ячейках которой будет написаны номер группы к которой относится лоскут в этой позиции. Её можно нажимать несколько раз, пока вам не понравится вариант расположения лоскутов.</li>
    </ol>
    <p>Кнопка «Сгенерировать» может быть не доступна в случае если количество ячеек (ширина * высоту) не будет соответствовать сумме лоскутов указанных на третьем этапе. При этом будет отображаться сообщение об этом.</p>
    <h2>Что такое «Вариант палитры», «Вариант комбинации» и «Настроить вариативность»</h2>
    <p>«Настроить вариативность» — это возможность настроить:</p>
    <ul>
        <li>палитру цветов для лоскутов в сгенерированной картинке</li>
        <li>вариант комбинации расположения лоскутов</li>
    </ul>
    <p>«Вариант палитры» — это число на основе которой создаётся уникальная палитра для лоскутов на сгенерированной картинке. Она изменяется при каждом обновлении страницы.</p>
    <p>«Вариант комбинации» — это число отвечает за то, как будут располагаться лоскуты на одеяле.</p>
    <p>Оба числа позволяют в точности повторить ранее сгенерированный рисунок одеяла.</p>
</template>

<script setup>

</script>

<style lang="scss" scoped>

</style>
